<template>
	<Menu />
	
	<div>
		<div>
			<img class="mx-auto" :src="require('@/assets/nosotros/header.jpg')" alt="">
		</div>

		<div class="bg-repeat px-4 md:px-12 lg:px-24 py-12" :style="{ backgroundImage: 'url(' + require('@/assets/nosotros/green-fibers.png') + ')' }">
			<h1 class="italic text-2xl md:text-3xl font-semibold text-rojo">SOMOS RED PEPPER</h1>

			<div class="max-w-3xl mt-3 space-y-4">
				<p class="text-white leading-tight text-lg">Somos una agencia de soluciones gráficas, que lleva ideas y proyectos a materiales impresos únicos. Somos más que una imprenta.</p>

				<p class="text-gray-300 leading-tight">Somos un equipo de creativos y expertos en impresión, ingeniería en empaque y diseño, que busca materializar lo que quieres comunicar. Nuestros servicios son de gran calidad y a la medida del cliente; a quien acompañamos y asesoramos de incio a fin, para que reciba justo lo que quiere, cuando lo quiere.</p>

				<p class="text-gray-300 leading-tight">En Red Pepper sabemos que la impresión es un arte y que dominarlo requiere de años de experiencia; por eso alentamos a nuestros clientes a que se acerquen a nuestro equipo para ayudarlos a aterrizar sus necesidades. Somos expertos en desarrollar ideas desde cero y volverlas realidad con la mejor tecnología y servicio. Trabajamos como nuestro cliente prefiera; con su equipo interno o con agencias creativas, porque queremos que esté tranquilo y se dedique a su negocio, mientras nosotros solucionamos sus necesidades creativas con compromiso, innovación y eficiencia.</p>

				<p  class="text-gray-300 leading-tight">Producimos ideas impresas en cantidades tan pequeñas o tan masivas como nuestro cliente quiera, tan serias o tan arriesgadas, tan complejas o tan sencillas, tan urgentes o tan tranquilas. Nuestro expertise y creatividad no tienen límites. Superamos tus expectativas en cada paso.</p>
			</div>

			<div class="max-w-3xl mt-12 space-y-2">
				<h1 class="italic text-xl md:text-2xl font-semibold text-white">TECNOLOGÍA</h1>
				<p class="text-gray-300 leading-tight">En Red Pepper buscamos estar siempre a la vanguardia de la creatividad y las soluciones gráficas; por ello, contamos con tecnología de punta que nos permite lograr proyectos inimaginables y sacar lo mejor de nuestro equipo de expertos.</p>
			</div>
		</div>

		<div class="bg-repeat px-4 md:px-12 lg:px-28 py-12 md:py-16 bg-rojo md:grid grid-cols-3 gap-3" :style="{ backgroundImage: 'url(' + require('@/assets/grandes-ideas/brushed.png') + ')' }">
			<div class="mb-6 md:mb-0">
				<img class="w-56 mx-auto rounded-lg shadow-lg" :src="require('@/assets/nosotros/the-red-trip.png')" alt="">
			</div>
			<div class="col-span-2 font-semibold text-xl md:text-2xl text-center md:text-left">
				NOS IMPORTA QUE CONOZCAS PASO A PASO CÓMO TRABAJAREMOS CONTIGO PARA QUE TE SIENTAS <span class="text-black font-bold">CÓMODO Y TRANQUILO</span> EN TODO MOMENTO<span class="text-black font-bold">.</span>

				<div class="mt-4">
					<a href="https://firebasestorage.googleapis.com/v0/b/redpepper-tempo.appspot.com/o/The-red-trip-Redpepper.pdf?alt=media&token=b9387a11-4b64-4cba-9a02-53cea1a89774" target="_blank" download class="text-xs font-semibold bg-white rounded-full py-2 lg:py-3 px-5 text-black hover:bg-black hover:text-white">
						<DocumentDownloadIcon class="w-5 h-5 inline-block mr-1 mb-1" />
						DESCÁRGALO AQUÍ
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Menu from '@/components/core/Menu'
import { DocumentDownloadIcon } from '@heroicons/vue/solid'

export default {
	components: {
		Menu,
		DocumentDownloadIcon
	},
	setup() {
		
	},
}
</script>